<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-select :items="contactTypes" hide-details filled label="Тип контакту" v-model="contact_type"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="contact_type ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Значення контакту" v-model="contact_value"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="contact_value ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Представлення" v-model="print_name"
                          hide-details
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <AC_NaturalPerson
                :value="natural_people_id"
                @autocompleteChange="naturalPersonChange"
                color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-switch
                v-model="show_in_blank"
                inset
                hide-details
                :label="show_in_blank ? 'Виводити у бланку' : 'Не виводити у бланку'"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-switch
                v-model="show_in_bill"
                inset
                hide-details
                :label="show_in_bill ? 'Виводити у рахунках' : 'Не виводити у рахунках'"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-switch
                v-model="show_on_site"
                inset
                hide-details
                :label="show_on_site ? 'Відображати на сайті' : 'Не відображати на сайті'"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-switch
                v-model="other_contact"
                inset
                hide-details
                :label="other_contact ? 'Контакт іншого підприємства' : 'Контакт нашого підприємства'"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {CREATE_ORGANIZATION_CONTACT, REMOVE_ORGANIZATION_CONTACT, UPDATE_ORGANIZATION_CONTACT} from "@/store/actions/organization_contact";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import { contactTypeSelect } from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'organization_contact_modal_delete'

export default {
  name: "HWP_Modal_OrganizationContact",
  mixins: [ModalComponentMixin],
  components: {
    AC_NaturalPerson: () => import("@/components/autocomplite/AC_NaturalPerson"),
  },
  data() {
    return {
      contactTypes: contactTypeSelect,
      contact_type: this.item.contact_type || '',
      contact_value: this.item.contact_value || '',
      print_name: this.item.print_name || '',
      show_in_blank: this.item.show_in_blank || false,
      show_in_bill: this.item.show_in_bill || false,
      show_on_site: this.item.show_on_site || false,
      other_contact: this.item.other_contact || false,
      natural_people_id: this.item.natural_people_id || null,
    }
  },
  methods: {
    naturalPersonChange(payload) {
      if (payload) {
        this.natural_people_id = payload.value || null
      } else {
        this.natural_people_id = null
      }
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.contact_type = this.item.contact_type
      this.contact_value = this.item.contact_value
      this.print_name = this.item.print_name
      this.show_in_blank = this.item.show_in_blank
      this.show_in_bill = this.item.show_in_bill
      this.show_on_site = this.item.show_on_site
      this.other_contact = this.item.other_contact
      this.natural_people_id = this.item.natural_people_id

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      // this.$emit('closeModal')
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення контактної інформації: ${this.contact_value}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Тип контакту та його значення - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        contact_type: this.contact_type,
        contact_value: this.contact_value,
        print_name: this.print_name,
        show_in_bill: this.show_in_bill || false,
        show_in_blank: this.show_in_blank || false,
        show_on_site: this.show_on_site || false,
        other_contact: this.other_contact || false,
        natural_people_id: this.natural_people_id || null
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_ORGANIZATION_CONTACT, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_ORGANIZATION_CONTACT, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.contact_type = payload.contact_type
              this.contact_value = payload.contact_value
              this.print_name = payload.print_name
              this.show_in_blank = payload.show_in_blank
              this.show_in_bill = payload.show_in_bill
              this.show_on_site = payload.show_on_site
              this.other_contact = payload.other_contact
              this.natural_people_id = payload.natural_people_id
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_ORGANIZATION_CONTACT, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
